import { mainCacheAxios as axios } from '@/base/axios'
import { REQUEST_LOCALES, RECEIVE_LOCALES, SET_LANGUAGE, RECEIVE_TRANSLATIONS } from '@/actions/types'
import { API } from '@/constants'

const requestLocales = () => ({ type: REQUEST_LOCALES })

const receiveLocales = (data) => ({
  type: RECEIVE_LOCALES,
  payload: data,
})

const receiveTranslations = (data) => ({
  type: RECEIVE_TRANSLATIONS,
  payload: data,
})

const fetchLocales = () => async (dispatch) => {
  dispatch(requestLocales())
  if (window.languages) {
    dispatch(receiveLocales(window.languages))
  } else {
    const response = await axios.get(API.LOCALES)
    dispatch(receiveLocales(response.data))
  }
}

const setLanguage = () => {
  const iso2ToIso3 = {
    it: 'ITA',
    en: 'ENG',
    fr: 'FRA',
  }
  const convertToIso3 = (iso2) => iso2ToIso3[iso2] || null
  return ({
    type: SET_LANGUAGE,
    iso2: window.pathSite,
    iso3: convertToIso3(window.pathSite),
  })
}

const setTranslations = (content) => async (dispatch) => {
  const translations = content?.wpml_translations || []
  if (translations.length > 0) {
    dispatch(receiveTranslations(translations))
  } else {
    dispatch(receiveTranslations([]))
  }
}

export {
  fetchLocales,
  setLanguage,
  setTranslations,
}
