export const LOADING_SET_READY = 'LOADING_SET_READY'
export const LOADING_SET_FONT_READY = 'LOADING_SET_FONT_READY'
export const LOADING_SET_INC_STATUS = 'LOADING_SET_INC_STATUS'
export const LOADING_FIRST_LOAD = 'LOADING_FIRST_LOAD'
export const LOADING_SET_COOKIE = 'LOADING_SET_COOKIE'
export const LOADING_SET_REAL_LINK = 'LOADING_SET_REAL_LINK'

export const LOCALE_GET_ALL = 'LOCALE_GET_ALL'

export const PAGE_ADD_CONTENT = 'PAGE_ADD_CONTENT'
export const PAGE_ADD_MEDIA = 'PAGE_ADD_MEDIA'
export const PAGE_ADD_POST = 'PAGE_ADD_POST'
export const PAGE_ADD_FORM = 'PAGE_ADD_FORM'
export const PAGE_SEND_FORM = 'PAGE_SEND_FORM'
export const PAGE_STATUS_FORM = 'PAGE_STATUS_FORM'
export const PAGE_LIST_CONTENT = 'PAGE_LIST_CONTENT'
export const PAGE_LIST_TAXONOMIES = 'PAGE_LIST_TAXONOMIES'
export const SET_NEGATIVE = 'SET_NEGATIVE'

export const PAGE_SET_LAYOUT = 'PAGE_SET_LAYOUT'
export const PAGE_SET_TRANSITION = 'PAGE_SET_TRANSITION'
export const PAGE_SET_SHOW = 'PAGE_SET_SHOW'
export const PAGE_SET_CURRENT = 'PAGE_SET_CURRENT'

export const NAV_SET_OPEN = 'NAV_SET_OPEN'
export const NAV_SET_COMPACT = 'NAV_SET_COMPACT'
export const NAV_GET_ALL = 'NAV_GET_ALL'
export const NAV_GET_MAIN = 'NAV_GET_MAIN'
export const NAV_GET_MOBILE = 'NAV_GET_MOBILE'
export const NAV_GET_EXTRA = 'NAV_GET_EXTRA'
export const NAV_GET_GENERIC = 'NAV_GET_GENERIC'
export const NAV_SET_BACKGROUND = 'NAV_SET_BACKGROUND'

export const SET_SCROLL_TO = 'SET_SCROLL_TO'
export const ROUTING_ADD = 'ROUTING_ADD'

export const MODAL_SET_OPEN = 'MODAL_SET_OPEN'
export const MODAL_SET_IMAGE = 'MODAL_SET_IMAGE'
export const MODAL_SET_TEXT = 'MODAL_SET_TEXT'
export const MODAL_SET_NODE = 'MODAL_SET_NODE'

export const MEDIA_QUERIES_SET = 'MEDIA_QUERIES_SET'
export const MEDIA_QUERIES_SET_KEY = 'MEDIA_QUERIES_SET_KEY'

export const GET_SEARCH = 'GET_SEARCH'

export const STRING_GET_ALL = 'STRING_GET_ALL'

export const POINTER_PROGRESS_TIME = 'POINTER_PROGRESS_TIME'
export const POINTER_HOVER = 'POINTER_HOVER'
export const POINTER_ICON = 'POINTER_ICON'
export const POINTER_LABEL = 'POINTER_LABEL'

export const REQUEST_LOCALES = 'REQUEST_LOCALES'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const RECEIVE_LOCALES = 'RECEIVE_LOCALES'
export const RECEIVE_TRANSLATIONS = 'RECEIVE_TRANSLATIONS'

export const RECEIVE_NAV = 'RECEIVE_NAV'
export const REQUEST_NAV = 'REQUEST_NAV'
export const REQUEST_MENU_LOCATION = 'REQUEST_MENU_LOCATION'

export const REQUEST_ROUTING = 'REQUEST_ROUTING'
export const RECEIVE_ROUTING = 'RECEIVE_ROUTING'

export const OPEN_LAYER = 'OPEN_LAYER'
export const CLOSE_LAYER = 'CLOSE_LAYER'
export const CLOSE_ALL_LAYER = 'CLOSE_ALL_LAYER'
export const CREATE_LAYER_MODEL = 'CREATE_LAYER_MODEL'
export const PUSH_PROJECT = 'PUSH_PROJECT'
export const RESET_PROJECT = 'RESET_PROJECT'
