const style = (theme) => {
  const output = {
    root: {
      background: theme.colors[1],
      padding: theme.padding[1],
      '& img': {
        verticalAlign: 'middle',
        maxWidth: '100%',
      },
      '& a': {
        color: theme.colors[8],
      },
      '& button': {
        color: theme.colors[8],
      },
    },
    text: {
      color: theme.colors[8],
      fontSize: 14,
      lineHeight: 1.8,
    },
    footerRow: {
      display: 'flex',
    },
    footerMenu: {
      marginTop: 28,
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    footerNavItem: {
      textTransform: 'uppercase',
      letterSpacing: '0.34em',
      fontSize: 9,
      textDecoration: 'underline',
      textAlign: 'left',
      marginLeft: 0,
      marginRight: 0,
      padding: 0,
      cursor: 'pointer',
    },
    footerLeft: {
      flex: '0 0 20%',
      maxWidth: '20%',
      '& button': {
        textTransform: 'uppercase',
        letterSpacing: '0.34em',
        fontSize: 9,
        textDecoration: 'underline',
        textAlign: 'left',
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        cursor: 'pointer',
      },
      '& .pretit': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '10px',
        '& > *': {
          flex: 1,
        },
      },
    },
    footerCenter: {
      flex: '1 1 auto',
      paddingRight: 40,
    },
    footerRight: {
      textAlign: 'right',
      flex: '1 0 auto',
    },
    footerTop: {
      marginBottom: 60,
      '& .pretit': {
        marginTop: 8,
        marginBottom: 23,
      },
    },
    footerBottom: {
      alignItems: 'center',
      '& .pretit': {
        transform: 'translateY(7px)',
        marginBottom: 0,
      },
    },
    footerBottom1: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 60,
      paddingTop: 30,
      borderTop: `1px solid ${theme.getRgba(theme.colors[8], 0.3)}`,
      '& $text': {
        fontSize: 12,
        lineHeight: 1.2,
        textAlign: 'center',
      },
    },
    footerBottom2: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 30,
      paddingTop: 30,
      borderTop: `1px solid ${theme.getRgba(theme.colors[8], 0.3)}`,
      '& $text': {
        fontSize: 12,
        lineHeight: 1.2,
        textAlign: 'center',
      },
    },
    footerBottom1Left: {
      marginBottom: 20,
      '& p': {
        margin: 0,
      },
    },
    footerBottom1Right: {
      flexShrink: 0,
      width: 375,
      '& img': {
        display: 'block',
        width: '100%',
      },
    },
    subscribeNewsletter: {
      textAlign: 'left',
      float: 'right',
      whiteSpace: 'nowrap',
    },
    input: {
      background: theme.colors[5],
      borderRadius: 18,
      fontSize: 14,
      padding: '10px 30px',
      width: 255,
      marginRight: 22,
      color: theme.colors[8],
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      display: 'inline-block',
    },
    submit: {
      borderRadius: '50%',
      width: 37,
      height: 37,
      background: theme.colors[4],
      display: 'flex',
      float: 'right',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg': {
        transform: 'rotate(-90deg)',
        width: 8,
        height: 12,
        fill: theme.colors[1],
        transition: 'transform .2s ease-out',
      },
      '&:hover': {
        '& svg': {
          transform: 'translateX(2px) rotate(-90deg)',
        },
      },
    },
    customRadio: {
      fontSize: 11,
      color: theme.colors[8],
      '& label': {
        position: 'relative',
        zIndex: 1,
        cursor: 'pointer',
        userSelect: 'none',
        '& input': {
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          opacity: 0.01,
          '&:checked': {
            '& + span': {
              '&:after': {
                opacity: 1,
              },
            },
          },
        },
      },
      '& span': {
        position: 'relative',
        zIndex: 1,
        paddingLeft: 23,
        marginTop: 20,
        display: 'inline-block',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 1,
          left: 0,
          width: 14,
          height: 14,
          borderRadius: '50%',
          background: theme.colors[5],
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 6,
          left: 5,
          width: 4,
          height: 4,
          borderRadius: '50%',
          background: theme.colors[4],
          opacity: 0,
          transition: 'opacity .16s ease-out',
        },
      },
    },
  }

  /*------------------------------
MD
------------------------------*/
  output[theme.mediaInverse.md] = {
    footerRow: {
      flexWrap: 'wrap',
    },
    footerLeft: {
      flex: '0 0 100%',
      maxWidth: 'none',
      marginBottom: 40,
    },
    root: {
      paddingLeft: theme.paddingMobile[1],
      paddingRight: theme.paddingMobile[1],
    },
    footerCenter: {
      flex: '0 0 50%',
      maxWidth: '50%',
    },
    footerRight: {
      flex: '0 0 50%',
      maxWidth: '50%',
    },
    footerBottom1Right: {
      flexBasis: '50%',
    },
    footerBottom1Left: {
      flexBasis: '50%',
    },
  }

  /*------------------------------
XS
------------------------------*/
  output[theme.mediaInverse.xs] = {
    root: {
      paddingTop: 40,
    },
    footerCenter: {
      flex: '0 0 100%',
      maxWidth: 'none',
      fontSize: 14,
      marginBottom: 20,
      paddingRight: 0,
    },
    footerRight: {
      flex: '0 0 100%',
      maxWidth: 'none',
      textAlign: 'left',
    },
    subscribeNewsletter: {
      float: 'none',
    },
    footerBottom1: {
      display: 'block',
    },
    footerBottom1Left: {
      marginRight: 0,
    },
    footerBottom1Right: {
      width: '100%',
      marginTop: 40,
    },
  }

  return output
}

export default style
