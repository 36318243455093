import injectSheet from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import HTMLParser from 'react-html-parser'
import NewsletterSubscription from '@/components/NewsletterSubscription'
import DelayLink from '@/components/DelayLink'
import { cleanOrigin } from '@/utils/path'
import style from './style'

function Footer({
  classes,
  data,
  strings,
  theme,
}) {
  const { footerLeftMenu } = useSelector((state) => ({
    footerLeftMenu: state.nav['footerLeftMenu'] || [],
  }), shallowEqual)
  return (
    <footer className={`${classes.root} footer`}>
      <div className={`${classes.footerTop} ${classes.footerRow}`}>
        <div className={classes.footerLeft}>
          <div className={classes.footerLogo}>
            <svg
              className="first"
              xmlns="http://www.w3.org/2000/svg"
              width="76"
              height="15"
              preserveAspectRatio="xMidYMin slice"
            >
              <use xlinkHref="#ico-logo" />
            </svg>
          </div>
          <div className={classes.footerMenu}>
            {footerLeftMenu.map((item, index) => {
              return (
                <div
                  className={classes.footerNavItem}
                  key={index.toString()} // eslint-disable-line
                >
                  <a
                    href={item.url}
                    target="_blank"
                  >
                    {item.title}
                  </a>
                </div>
              )
            })}
          </div>
        </div>
        <div className={classes.footerCenter}>
          <div className="pretit">{(data.title !== undefined) ? HTMLParser(data.title) : ''}</div>
          <div className={classes.text}>
            {(data.text !== undefined) ? HTMLParser(data.text) : ''}
          </div>
        </div>
        <div className={classes.footerRight}>
          <div className={classes.subscribeNewsletter}>
            <NewsletterSubscription
              strings={strings}
              data={{
                title: 'Newsletter',
                privacy: strings.newsletter_disclaimer,
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${classes.footerBottom} ${classes.footerRow}`}>
        <div className={classes.footerLeft}>
          {/* <div className="pretit">
            <a href={strings.privacy_link} target="_blank">privacy policy</a>
            {strings.manage_cookies_label && (
            <button
              onClick={() => {
                const link = document.querySelector('.iubenda-tp-btn.iubenda-cs-preferences-link')
                if (link) link.click()
              }}
              data-layer="open-iub-preferences"
            >
              {strings.manage_cookies_label}
            </button>
            )}
          </div> */}
        </div>
        <div className={classes.footerCenter}>
          <img src={`${theme.IMAGE_URL}adimember.jpg`} alt="Adi Member" />
          <DelayLink to={strings.footer_russ}><img src={`${theme.IMAGE_URL}ciamrus.jpg`} alt="Ciam Rus" /></DelayLink>
          <DelayLink to={cleanOrigin(strings.footer_east)}><img src={`${theme.IMAGE_URL}ciam_middle_east.jpg`} alt="Ciam Middle East" /></DelayLink>
        </div>
        <div className={classes.footerRight}>
          <img src={data.imgRight} alt="" />
        </div>
      </div>
    </footer>
  )
}

export default injectSheet(style)(Footer)
