const style = (theme) => {
  const output = {
    root: {
    },
    input: {
      borderRadius: 18,
      fontSize: 14,
      padding: '10px 30px 10px 14px',
      width: 255,
      marginRight: 22,
      boxShadow: 'none',
      outline: 'none',
      display: 'inline-block',
      background: theme.getRgba(theme.colors[5], 0.5),
      transition: 'border-color .3s ease-in-out, background-color .3s ease-in-out',
      border: '1px solid transparent',
      '&:focus': {
        background: theme.getRgba(theme.colors[5], 1),
      },
      '&.error': {
        borderColor: 'rgba(255, 0, 0, .8)',
      },
    },
    form: {},
    radio: {},
    newUser: {
      color: theme.colors[8],
      fontSize: 14,
      lineHeight: 1.8,
      display: 'none',
    },
    oldUser: {
      color: theme.colors[8],
      fontSize: 14,
      lineHeight: 1.8,
      display: 'none',
    },
    button: {
      extend: [theme.typography.button],
      padding: 0,
    },
    submit: {
      borderRadius: '50%',
      width: 37,
      height: 37,
      background: theme.colors[4],
      display: 'flex',
      float: 'right',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg': {
        transform: 'rotate(-90deg)',
        width: 8,
        height: 12,
        fill: theme.colors[1],
        transition: 'transform .2s ease-in-out, fill .2s ease-out',
      },
      '&:hover': {
        '& svg': {
          transform: 'translateX(2px) rotate(-90deg)',
        },
      },
    },
    customRadio: {
      maxWidth: 255,
      fontSize: 11,
      color: theme.colors[8],
      transition: 'color .2s ease-in-out',
      '& a': {
        transition: 'color .2s ease-in-out',
      },
      '&.error': {
        color: '#cc0000',
        '& a': {
          color: '#cc0000',
        },
        '& span': {
          '&:before': {
            background: '#cc0000',
          },
        },
      },
      '& label': {
        position: 'relative',
        zIndex: 1,
        cursor: 'pointer',
        userSelect: 'none',
        '& input': {
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          opacity: 0.01,
          '&:checked': {
            '& + span': {
              '&:before': {
                background: theme.getRgba(theme.colors[5], 1),
              },
              '&:after': {
                opacity: 1,
              },
            },
          },
        },
      },
      '& span': {
        position: 'relative',
        zIndex: 1,
        paddingLeft: 23,
        marginTop: 20,
        display: 'inline-block',
        whiteSpace: 'normal',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 1,
          left: 0,
          width: 14,
          height: 14,
          borderRadius: '50%',
          transition: 'background-color .3s ease-in-out',
          background: theme.getRgba(theme.colors[5], 0.5),
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 6,
          left: 5,
          width: 4,
          height: 4,
          borderRadius: '50%',
          background: theme.colors[4],
          opacity: 0,
          transition: 'opacity .16s ease-out',
        },
      },
    },
  }
  return output
}

export default style
